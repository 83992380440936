import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteModalProps from "../interfaces/Deletemodalprops";
import { TEXT_MESSAGES } from "../const";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  entity,
  entityname,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
          position: "relative",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          height: "219px",
          width: "343px",
          padding: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection:"column",
          justifyContent: "center",
          alignItems: "center",
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: "18px",
          padding: "0",
          marginBottom: "8px",
          position: "relative",
        }}
      >
         <Box
            sx={{
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          </Box>
          <Typography variant="h6" component="div">
            {TEXT_MESSAGES.DELETE_INFO}
          </Typography>
        
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.87)",
          letterSpacing: "0.15px",
          fontWeight: 400,
          fontFamily: "Inter",
          padding: "0",
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
           {entity}'s Information
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          gap:"20px",
          position:"absolute",
          top:"60%",
          left:"20%",
          
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 4,
            borderColor: "#152726",
            color: "black",
            "&:hover": {
              borderColor: "#152726",
              color: "black",
              opacity: 0.9,
            },
          }}
        >
          {TEXT_MESSAGES.NO}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          sx={{
            px: 4,
            bgcolor: "black",
            "&:hover": {
              bgcolor: "black",
              opacity: 0.9,
            },
          }}
        >
          {TEXT_MESSAGES.YES}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
