import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import PheonixSearchProps from "../interfaces/PheonixSearchprops";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const PheonixSearch: React.FC<PheonixSearchProps> = ({  value, onChange }) => {
  const [query, setquery] = useState(value);
  const [isFocused, setIsFocused] = useState(false); 

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setquery(event.target.value);
  };
  const handleSearchClick = () => {
    onChange(query);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchClick();
    }
  };
  const handleClear = () => {
    setquery("");
    onChange("");
  };


  return (
    <div style={{ display: "flex", alignItems: "center",marginRight:"10px" }}>
      <TextField
        variant="outlined"
        size="small"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Search..."
        style={{
          marginTop: "10px",
          width:"220px",
          color: "#152726",
          border: "none",
          borderBottom: "2px solid #152726",
          gap:"8px",
          
        }}
        onFocus={() => setIsFocused(true)} 
        onBlur={() => setIsFocused(false)}  
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleSearchClick}>
                <SearchIcon
                  style={{
                    color: isFocused ? "#152726" : "#152726", 
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {query && (
                <IconButton onClick={handleClear}>
                  <CloseIcon style={{ color: "#152726" }} />
                </IconButton>
              )}
            </InputAdornment>
          ),

          sx: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#152726",
            },
          },
        }}
      />
    </div>
  );
};

export default PheonixSearch;
