import type React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ModalFormData from "../interfaces/ModalFormDataProps";
import { TEXT_MESSAGES } from "../const";

interface EditConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  productName: string;
  onConfirm: (selectedRow: ModalFormData) => void;
  selectedProduct: ModalFormData | null;
}

const EditConfirmationDialog: React.FC<EditConfirmationDialogProps> = ({
  open,
  onClose,
  productName,
  onConfirm,
  selectedProduct,
}) => {
  const handleEdit = () => {
    if (selectedProduct) {
      onConfirm(selectedProduct);
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "400px",
          p: 2,
          borderRadius: "8px",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EditIcon />
          </Box>
          <Typography
            variant="h6"
            component="div"
            style={{ fontFamily: "Inter", fontWeight: 700 }}
          >
            {TEXT_MESSAGES.EDIT_INFO}
          </Typography>
          <Typography variant="body2" color="black">
            <span style={{ fontWeight: "bold" }}>"{productName}"</span>{" "}
            {TEXT_MESSAGES.INFORMATION}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 3, gap: 1 }}>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            px: 4,
            borderColor: "rgba(0, 0, 0, 0.12)",
            color: "text.primary",
          }}
        >
          {TEXT_MESSAGES.NO}
        </Button>
        <Button
          variant="contained"
          onClick={handleEdit}
          sx={{
            px: 4,
            bgcolor: "black",
            "&:hover": {
              bgcolor: "black",
              opacity: 0.9,
            },
          }}
        >
          {TEXT_MESSAGES.YES}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditConfirmationDialog;
