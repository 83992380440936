import type React from "react";
import { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  LinearProgress,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMutation } from "@apollo/client";
import { UPLOAD_CSV } from "../graphql/query";
import baseUrl from "../api";
import axios from "axios";
import { TEXT_MESSAGES } from "../const";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface ImportBulkFileProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImportBulkFile: React.FC<ImportBulkFileProps> = ({ isOpen, onClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [error, setError] = useState<string>("");

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.type === "text/csv") {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (!selectedFile.name.toLowerCase().endsWith(".csv")) {
        setError("Invalid file type. Please upload a CSV file.");
        setFile(null);
        e.target.value = "";
        return;
      }
      setError("");
      setFile(selectedFile);
    }
  };

  const handleDownloadSample = () => {
    const sampleData = `Product,Product description,Quantity,Unit,GST Type,GST Percentage,HSN Code,Stock,Entry date,Expiry date,Stock Alert,Notes,Purchase price,Purchase rate,Purchase unit,Purchase GST,Purchase GST price,Sales price,Sales rate,Sales Unit,Sale GST,Sales GST price,\nProduct 1,productdescription,100,Bags,Taxable,12%,11,23,2025-02-05,2025-02-07,10,productnotes,749.64,809.43,Bags,5%,98.57,539.82,974.35,Gram,5%,66`;
    const blob = new Blob([sampleData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample-product-import.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const simulateProgress = () => {
    const timer = setInterval(() => {
      setUploadProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return timer;
  };

  const handleSave = async () => {
    if (file) {
      setIsUploading(true);
      setUploadProgress(0);

      try {
        const formData = new FormData();
        formData.append(
          "operations",
          JSON.stringify({
            query: UPLOAD_CSV.loc?.source.body,
            variables: { file: null },
          })
        );
        formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
        formData.append("0", file);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(baseUrl, formData, config);

        const { success, message } = response.data.data.uploadCSV;

        if (success) {
          setUploadProgress(100);
          setUploadSuccess(true);
          setTimeout(() => {
            onClose();
            setFile(null);
            setIsUploading(false);
            setUploadProgress(0);
            setUploadSuccess(false);
          }, 1500);
        } else {
          console.error(
            "Upload failed:",
            message || "Unknown error from server"
          );
          setUploadProgress(0);
          setIsUploading(false);
        }
      } catch (error: any) {
        console.error("Network error:", error.message || error);
        setUploadProgress(0);
        setIsUploading(false);
      }
    }
  };

  const handleClose = () => {
    onClose();
    setFile(null);
    setIsUploading(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="import-bulk-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "90%" : isTabletScreen ? "450px" : "500px",
          height: isSmallScreen ? "353px" : isTabletScreen ? "420px" : "450px",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: isSmallScreen ? 2 : 3,
        }}
      >
        {uploadSuccess ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 3,
              marginTop: "40px",
            }}
          >
            <CheckCircleIcon
              sx={{ fontSize: 48, color: "success.main", mb: 2 }}
            />
            <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
              {TEXT_MESSAGES.FILE_UPLOAD_SUCCESSFULLY}
            </Typography>
            <Button onClick={handleClose}>Close</Button>
          </Box>
        ) : (
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                id="import-bulk-modal-title"
                variant="h6"
                sx={{ fontWeight: 600, color: "#4A4A4A" }}
              >
                {TEXT_MESSAGES.IMPORT_BULK_PRODUCT}
              </Typography>
              <IconButton
                onClick={handleClose}
                size="small"
                sx={{ p: 0.5 }}
                aria-label="close"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>

            <Paper
              elevation={0}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              sx={{
                marginTop: isSmallScreen ? 5 : 10,
                height: isSmallScreen ? "146px" : 150,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: 2,
                borderStyle: "dashed",
                borderColor: isDragging ? "primary.main" : "grey.300",
                borderRadius: 2,
                bgcolor: isDragging ? "primary.50" : "background.paper",
                transition: "all 0.2s ease",
                textAlign: "center",
                p: isSmallScreen ? 2 : 3,
                position: "relative",
              }}
            >
              {isUploading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                    backgroundColor: "#F0F0FA",
                    height: "52px",
                  }}
                >
                  <Box sx={{ width: "80%", mb: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                      sx={{
                        height: 8,
                        borderRadius: 4,
                        marginTop: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#1a73e8",
                          borderRadius: 4,
                        },
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={(e) => {
                    const target = e.target as HTMLElement;
                    if (!target.closest(".file-input-wrapper")) {
                    }
                  }}
                >
                  <div
                    className="file-input-wrapper"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      style={{
                        position: "absolute",
                        inset: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                    <FileUploadIcon
                      sx={{ fontSize: 40, color: "text.secondary", mb: 2 }}
                    />
                  </div>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {file ? file.name : "Choose file to upload"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {TEXT_MESSAGES.SUPPORT}
                  </Typography>
                  {error && (
                    <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
                      {error}
                    </Typography>
                  )}
                </div>
              )}
            </Paper>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginTop: isSmallScreen ? 7 : 13,
                gap: isSmallScreen ? 2 : 0,
              }}
            >
              <Button
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={handleDownloadSample}
                disabled={isUploading}
                sx={{
                  backgroundColor: "#F0F0FA",
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "black",
                  textDecoration: "underline",
                  width: "auto",
                  height: "26px",
                  whiteSpace: "nowrap",
                }}
              >
                {TEXT_MESSAGES.SAMPLE_FILE}
              </Button>

              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "row",
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    width: isSmallScreen ? "100%" : "97px",
                    height: "40px",
                    color: "black",
                    textDecoration: "underline",
                  }}
                  disabled={isUploading}
                >
                  {TEXT_MESSAGES.CANCEL}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{
                    width: isSmallScreen ? "100%" : "auto",
                    px: 4,
                    backgroundColor: "#000000",
                    "&:hover": {
                      bgcolor: "#000000",
                    },
                  }}
                >
                  {isUploading ? "Uploading..." : "Save"}
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ImportBulkFile;
