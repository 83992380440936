import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Switch,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import { TEXT_MESSAGES } from "../const";

interface ProductDetailsProps {
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  error?: string | null;
  readOnly?: boolean;
  checkAll?: boolean;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  data,
  onSave,
  error,
  readOnly,
  checkAll,
}) => {
  const [isTaxable, setIsTaxable] = useState(true);
  const [gst, setGST] = useState<string | null>(null);
  const [unit, setUnit] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState(data);
  const [productNameError, setProductNameError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState({
    gst_percentage: false,
    gst_type: false,
  });

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    if (formData.isTaxable) {
      setTouched((prev) => ({
        ...prev,
        gst_percentage: true,
        gst_type: true,
      }));
    }
  }, []);

  const handleTouched = (field: any) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const handleInputChange = (field: string, value: any) => {
    let parsedValue = value;

    if (field === "product_name") {
      setProductNameError(null);
    }
    if (field === "gst_percentage") {
      if (!value.endsWith("%")) {
        parsedValue = `${value}%`;
      }
    } else if (["quantity", "hsn_code"].includes(field)) {
      if (value === "") {
        parsedValue = "";
      } else {
        parsedValue = parseInt(value, 10);
        if (isNaN(parsedValue)) {
          parsedValue = "";
        }
      }
    }

    const updatedData = { ...formData, [field]: parsedValue };
    setFormData(updatedData);

    onSave(updatedData);
  };

  const formatDate = (date: string): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
    },
    autoComplete: {
      width: "100%",
      maxWidth: "348px",
      "& .MuiAutocomplete-root": {
        color: "#00000061",
      },
    },
  };

  const gstType = [
    { value: "Taxable", label: "Taxable" },
    { value: "Non-Taxable", label: "Non-Taxable" },
    { value: "Out of scope", label: "Out of scope" },
    { value: "Non-GST supply", label: "Non-GST supply" },
  ];
  const gstOptions = [
    { value: "0%", label: "0%" },
    { value: "5%", label: "5%" },
    { value: "12%", label: "12%" },
    { value: "18%", label: "18%" },
    { value: "28%", label: "28%" },
  ];

  const unitOptions = [
    { value: "Bags", label: "Bags" },
    { value: "Kilogram", label: "Kilogram" },
    { value: "Gram", label: "Gram" },
    { value: "Centimeter", label: "Centimeter" },
  ];

  const handleSwitchChange = () => {
    setIsTaxable(!isTaxable);
  };

  const handleGSTpercentage = (_: React.SyntheticEvent, newValue: any) => {
    setGST(newValue?.value || "");
  };
  const handleGSTtype = (_: React.SyntheticEvent, newValue: any) => {
    setGST(newValue?.value || "");
    setIsTaxable(newValue?.value === "Taxable");
  };

  const handleUnitChange = (_: React.SyntheticEvent, newValue: any) => {
    setUnit(newValue?.value || "");
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen
          ? "column"
          : isMediumScreen
          ? "column"
          : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "24px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: isSmallScreen ? "stretch" : "flex-start",
        }}
      >
        <PheonixTextField
          type="text"
          label="Product Name"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.product_name || ""}
          error={!!error && !formData.product_name}
          helperText={
            !!error && !formData.product_name ? "Product Name is required" : ""
          }
          onChange={(e) => {
            handleInputChange("product_name", e.target.value);
          }}
          sx={commonStyles.textField}
        />
        <PheonixTextField
          label="Quantity"
          type="text"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.quantity || ""}
          error={!!error && !formData.quantity}
          helperText={
            !!error && !formData.quantity ? "Quantity is required" : ""
          }
          onChange={(e) => handleInputChange("quantity", e.target.value)}
          sx={commonStyles.textField}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "348px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: "14px",
                whiteSpace: "nowrap",
                fontFamily: "Lexend",
              }}
            >
              {TEXT_MESSAGES.GST_TYPE}
            </Typography>
            <Switch
              checked={readOnly ? true : formData.isTaxable}
              disabled={readOnly}
              onChange={() =>
                handleInputChange("isTaxable", !formData.isTaxable)
              }
              size="small"
              sx={{
                width: "40px",
                padding: "0",
                "& .MuiSwitch-thumb": {
                  width: "16px",
                  height: "16px",
                  backgroundColor: "black",
                  boxShadow: "none",
                },
                "& .MuiSwitch-switchBase": {
                  padding: "2px",
                  "&.Mui-checked": {
                    transform: "translateX(20px)",
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "black",
                    },
                  },
                },
                "& .MuiSwitch-track": {
                  borderRadius: "10px",
                  backgroundColor: "gray",

                  width: "26px",
                  height: "10px",
                  margin: "5px",
                  transition: "background-color 0.2s",
                  "&.Mui-checked": {
                    backgroundColor: "black",
                  },
                },
              }}
            />
          </Box>
          {(readOnly || formData.isTaxable) && (
            <Autocomplete
              options={gstType}
              disabled={readOnly}
              getOptionLabel={(option) => option.label}
              value={
                gstType.find((option) => option.value === formData.gst_type) ||
                null
              }
              onChange={(_, newValue) => {
                handleTouched("gst_type");
                handleInputChange("gst_type", newValue?.value || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={formData.isTaxable}
                  disabled={readOnly}
                  label=""
                  variant="standard"
                  error={
                    formData.isTaxable && touched.gst_type && !formData.gst_type
                  }
                  helperText={
                    formData.isTaxable && touched.gst_type && !formData.gst_type
                      ? "GST Type is required"
                      : ""
                  }
                  onBlur={() => handleTouched("gst_type")}
                  sx={commonStyles.textField}
                />
              )}
              sx={{
                visibility:readOnly || formData.isTaxable ? "visible" : "hidden",
                width: isSmallScreen ? "100%" : "348px",
                fontFamily: "Lexend",
              }}
            />
          )}
        </Box>

        <PheonixTextField
          label="Entry date"
          type="date"
          disabled={readOnly}
          variant="standard"
          value={formData.entrydate ? formatDate(formData.entrydate) : ""}
          error={!!error && !formData.entrydate}
          helperText={
            !!error && !formData.entrydate ? "Entry Date is required" : ""
          }
          onChange={(e) => handleInputChange("entrydate", e.target.value)}
          InputProps={{
            inputProps: {
              placeholder: formData.entrydate ? " " : " Entry date",
              max: new Date().toISOString().split("T")[0],
            },
          }}
          required
          sx={{
            width: isSmallScreen ? "100%" : "348px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: formData.entrydate ? 1 : 0,
            },
            "& .MuiInputBase-root": {},
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
          }}
        />

        <PheonixTextField
          label="HSN Code"
          type="text"
          disabled={readOnly}
          variant="standard"
          value={formData.hsn_code}
          onChange={(e) => handleInputChange("hsn_code", e.target.value)}
          sx={commonStyles.textField}
        />
      </Box>

      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Product Description"
          disabled={readOnly}
          variant="standard"
          required
          value={formData.product_description}
          error={!!error && !formData.product_description}
          helperText={
            !!error && !formData.product_description
              ? "Product Description is required"
              : ""
          }
          onChange={(e) =>
            handleInputChange("product_description", e.target.value)
          }
          sx={commonStyles.textField}
        />

        <Autocomplete
          options={unitOptions}
          disabled={readOnly}
          getOptionLabel={(option) => option.label}
          value={
            unitOptions.find((option) => option.value === formData.unit) || null
          }
          onChange={(_, newValue) =>
            handleInputChange("unit", newValue?.value || "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={readOnly}
              label="Unit"
              required
              error={!!error && !formData.unit}
              helperText={!!error && !formData.unit ? "Unit is required" : ""}
              variant="standard"
              sx={commonStyles.textField}
            />
          )}
          sx={commonStyles.autoComplete}
        />
        {(readOnly || formData.isTaxable) && (
          <Autocomplete
            options={gstOptions}
            disabled={readOnly}
            getOptionLabel={(option) => option.label}
            value={
              gstOptions.find(
                (option) => option.value === formData.gst_percentage
              ) || null
            }
            onChange={(_, newValue) => {
              handleTouched("gst_percentage");
              handleInputChange("gst_percentage", newValue?.value || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={formData.isTaxable}
                disabled={readOnly}
                label="GST Percentage"
                variant="standard"
                error={
                  formData.isTaxable &&
                  touched.gst_percentage &&
                  !formData.gst_percentage
                }
                helperText={
                  formData.isTaxable &&
                  touched.gst_percentage &&
                  !formData.gst_percentage
                    ? "GST Percentage is required"
                    : ""
                }
                onBlur={() => handleTouched("gst_percentage")}
                sx={commonStyles.textField}
              />
            )}
            sx={{
              visibility: readOnly || formData.isTaxable ? "visible" : "hidden",
              width: isSmallScreen ? "100%" : "348px",
              fontFamily: "Lexend",
            }}
          />
        )}
        <PheonixTextField
          label="Expiry date"
          type="date"
          variant="standard"
          disabled={readOnly}
          required
          value={formData.expirydate ? formatDate(formData.expirydate) : ""}
          error={!!error && !formData.expirydate}
          helperText={
            !!error && !formData.expirydate ? "Expiry Date is required" : ""
          }
          onChange={(e) => handleInputChange("expirydate", e.target.value)}
          InputProps={{
            inputProps: {
              placeholder: formData.expirydate ? " " : " Expiry date",
              min: new Date().toISOString().split("T")[0],
            },
          }}
          sx={{
            width: isSmallScreen ? "100%" : "348px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: formData.expirydate ? 1 : 0,
            },
            "& .MuiInputBase-root": {
              backgroundColor: "transparent",
            },
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ProductDetails;
