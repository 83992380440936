import React, { useState } from "react";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import { useTheme } from "@mui/material/styles";
import ProductDetails from "./Productdetails";
import StockDetails from "./Stockdetails";
import PriceDetails from "./Pricedetails";
import { TEXT_MESSAGES } from "../const";
import ModalFormData from "../interfaces/ModalFormDataProps";
import { UPDATE_PRODUCT_MUTATION } from "../graphql/query";
import baseUrl from "../api";
import axios from "axios";
import Sales from "./Sales";

interface EditProductProps {
  product: ModalFormData;
  onSave: (updatedProduct: ModalFormData) => void;
  onCancel: () => void;
  error?: string | null;
}

const steps = ["Product Details", "Stock Details", "Price Details"];
const ProductEdit: React.FC<EditProductProps> = ({
  product,
  onSave,
  onCancel,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [editedProduct, setEditedProduct] = useState<ModalFormData>(product);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const validateProductDetails = (data: Record<string, any>) => {
    const requiredFields = [
      "product_name",
      "product_description",
      "quantity",
      "unit",
      "entrydate",
      "gst_percentage",
      "expirydate",
    ];
    let errors: string[] = [];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors.push(`${field.replace(/_/g, " ")} is required`);
      }
    });
    return errors;
  };

  const validateStockDetails = (data: Record<string, any>) => {
    const requiredFields = ["open_stock"];
    let errors: string[] = [];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors.push(`${field.replace(/_/g, " ")} is required`);
      }
    });
    return errors;
  };

  const validatePriceDetails = (data: Record<string, any>) => {
    const requiredFields = [
      "purchase_price",
      "purchase_rate",
      "purchase_unit",
      "purchase_gst",
      "purchase_price_gst",
      "sales_price",
      "sales_rate",
      "sales_unit",
      "sales_gst",
      "sales_price_gst",
    ];
    let errors: string[] = [];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors.push(`${field.replace(/_/g, " ")} is required`);
      }
    });
    return errors;
  };

  const handleNext = async () => {
    let currentErrors: string[] = [];
    if (activeStep === 0) {
      currentErrors = validateProductDetails(editedProduct);
    } else if (activeStep === 1) {
      currentErrors = validateStockDetails(editedProduct);
    } else if (activeStep === 2) {
      currentErrors = validatePriceDetails(editedProduct);
    }

    if (currentErrors.length > 0) {
      setError(currentErrors.join(", "));
      return;
    }
    if (activeStep === steps.length - 1) {
      try {
        const { id, ...editeddata } = editedProduct;
        const variables = {
          id,
          ...editeddata,
        };

        const response = await axios.post(baseUrl, {
          query: UPDATE_PRODUCT_MUTATION.loc?.source.body,
          variables,
        });

        const updateProduct = response.data.data.editProduct;

        if (updateProduct?.statusCode === 200) {
          onSave(editedProduct);

          onCancel();
        } else {
          console.error(
            "Error from server:",
            updateProduct?.message || "Unknown error"
          );
        }
      } catch (error: any) {
        console.error("Network error:", error.message || error);
        if (error.response) {
          console.error("Error details:", error.response.data);
          alert(
            `Error: ${
              error.response.data.errors?.[0]?.message ||
              error.response.statusText ||
              "Internal Server Error"
            }`
          );
        }
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "16px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: isSmallScreen ? "90vh" : isMediumScreen ? "80vh" : "70vh",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: "16px", fontFamily: "Lexend" }}
        >
          {steps[activeStep]}
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: "#8C8C8C",
                    "&.Mui-active": {
                      color: "black",
                    },
                    "&.Mui-completed": {
                      color: "black",
                    },
                  },
                }}
                sx={{
                  fontFamily: "Lexend",
                  "& .MuiStepLabel-label": {
                    fontFamily: "Lexend",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ flexGrow: 1 }}>
          {activeStep === 0 && (
            <ProductDetails
              data={{
                product_name: editedProduct.product_name,
                entrydate: editedProduct.entrydate,
                expirydate: editedProduct.expirydate,
                hsn_code: editedProduct.hsn_code,
                product_description: editedProduct.product_description,
                quantity: editedProduct.quantity,
                unit: editedProduct.unit,
                gst_type: editedProduct.gst_type,
                gst_percentage: editedProduct.gst_percentage,
                isTaxable: editedProduct.isTaxable,
              }}
              onSave={(updatedData) =>
                setEditedProduct((prev) => ({ ...prev, ...updatedData }))
              }
              error={error}
            />
          )}
          {activeStep === 1 && (
            <StockDetails
              data={{
                open_stock: editedProduct.open_stock,
                stock_alert: editedProduct.stock_alert,
                stock_reference: editedProduct.stock_reference,
                stock_notes: editedProduct.stock_notes,
                entrydate: editedProduct.entrydate,
              }}
              onSave={(updatedData) =>
                setEditedProduct((prev) => ({ ...prev, ...updatedData }))
              }
              error={error}
            />
          )}
          {activeStep === 2 && (
            <PriceDetails
              data={{
                purchase_rate: editedProduct.purchase_rate,
                purchase_unit: editedProduct.purchase_unit,
                purchase_gst: editedProduct.purchase_gst,
                purchase_price_gst: editedProduct.purchase_price_gst,
                sales_rate: editedProduct.sales_rate,
                sales_unit: editedProduct.sales_unit,
                sales_gst: editedProduct.sales_gst,
                sales_price_gst: editedProduct.sales_price_gst,
                purchase_price: editedProduct.purchase_price,
                sales_price: editedProduct.sales_price,
              }}
              onSave={(updatedData) =>
                setEditedProduct((prev) => ({ ...prev, ...updatedData }))
              }
              error={error}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "auto",
          paddingX: "16px",
          paddingY: "10px",
          width: isSmallScreen ? "85%" : isMediumScreen ? "80%" : "90%",
          backgroundColor: "white",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {activeStep > 0 && (
            <PheonixButton
              label={TEXT_MESSAGES.BACK_BUTTON}
              width={isSmallScreen ? "79px" : "90px"}
              backgroundColor={"white"}
              borderColor={"black"}
              border="1px solid"
              color={"secondary"}
              variant="outlined"
              onClick={handleBack}
              sx={{
                color: "black",
                minWidth: "79px",
                minHeight: "33px",
                transform: "none",
                textTransform: "none",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "white",
                },
              }}
            ></PheonixButton>
          )}
          <PheonixButton
            label={
              activeStep === 2
                ? TEXT_MESSAGES.SAVE_BUTTON
                : TEXT_MESSAGES.NEXT_BUTTON
            }
            variant="outlined"
            width={isSmallScreen ? "79px" : "90px"}
            onClick={handleNext}
            sx={{
              backgroundColor: "black",
              minWidth: "79px",
              minHeight: "33px",
              color: "white",
              transform: "none",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          ></PheonixButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductEdit;
