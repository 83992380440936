import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PheonixBox } from "pheonixui";
import {
  useMediaQuery,
  Typography,
  Autocomplete,
  TextField,
  Box,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  ListSubheader,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixButton from "../Components/PheonixButton";
import { TEXT_MESSAGES } from "../const";
import {
  GET_ALL_PRODUCTS,
  ADD_STOCK_MUTATION,
  EDIT_STOCK_MUTATION,
} from "../graphql/query";
import baseUrl from "../api";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

interface AddStockProps {
  onClose: () => void;
  onSave: (data: any) => void;
  stockData?: any;
  viewMode?: boolean;
  editMode?: boolean;
  stockId?: string;
}

interface Product {
  id: string;
  name: string;
}

const AddStock: React.FC<AddStockProps> = ({
  onClose,
  onSave,
  stockData,
  viewMode = false,
  editMode = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const [gst, setGST] = useState<string | null>(null);
  const [unit, setUnit] = useState<string | null>(null);
  const [entrydate, setEntrydate] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [remarks, setRemarks] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productNames, setProductNames] = useState<Product[]>([]);
  const [error, setError] = useState({
    product: false,
    quantity: false,
    unit: false,
    entrydate: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const filteredProducts = productNames.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const { data, loading } = useQuery(GET_ALL_PRODUCTS);

  useEffect(() => {
    if (data?.getAllProducts?.products) {
      const productList = data.getAllProducts.products.map((product: any) => ({
        id: product.id,
        name: product.product_name,
      }));
      setProductNames(productList);
    }
  }, [data]);
  useEffect(() => {
    if (stockData && productNames.length > 0) {
      const matchedProduct =
        productNames.find((p) => p.name === stockData.product_name) || null;

      setSelectedProduct(matchedProduct);
      setQuantity(stockData.quantity?.toString() || "");
      setUnit(stockData.unit || null);
      setRemarks(stockData.remarks || "");

      if (stockData.entrydate) {
        setEntrydate(formatDate(stockData.entrydate));
      } else {
        setEntrydate("");
      }
    }
  }, [stockData, productNames]);

  const formatDate = (date: string): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const gstType = [
    { value: "Taxable", label: "Taxable" },
    { value: "Non-Taxable", label: "Non-Taxable" },
    { value: "Out of scope", label: "Out of scope" },
    { value: "Non-GST supply", label: "Non-GST supply" },
  ];

  const unitOptions = [
    { value: "Bags", label: "Bags" },
    { value: "Kilogram", label: "Kilogram" },
    { value: "Gram", label: "Gram" },
    { value: "Centimeter", label: "Centimeter" },
  ];

  const handleSave = async () => {
    let hasError = false;

    const newErrors = {
      product: !selectedProduct,
      quantity: !quantity,
      unit: !unit,
      entrydate: !entrydate,
    };

    setError(newErrors);

    hasError = Object.values(newErrors).some((error) => error);
    if (hasError) return;

    const variables = {
      product_name: selectedProduct!.name,
      quantity: parseInt(quantity, 10),
      unit,
      entrydate: entrydate,
      remarks,
    };

    try {
      if (editMode && stockData) {
        const response = await axios.post(baseUrl, {
          query: EDIT_STOCK_MUTATION.loc?.source.body,
          variables: { id: stockData?.id, ...variables },
        });

        const editStock = response.data.data?.editStock;
        if (editStock?.statusCode === 200) {
          onSave(editStock.stock);
          onClose();
        } else {
          setError(editStock?.message || "Failed to update stock");
        }
      } else {
        const response = await axios.post(baseUrl, {
          query: ADD_STOCK_MUTATION.loc?.source.body,
          variables,
        });

        const { addStock } = response.data.data;
        if (addStock?.statusCode === 201) {
          onSave(addStock.stock);
          onClose();
        } else {
          setError(addStock?.message || "Unknown error");
        }
      }
    } catch (error: any) {
      console.error("Network error:", error.message || error);
    }
  };

  return (
    <PheonixBox
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "10px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: "70vh",
        position: "relative",
        boxSizing: "border-box",
      }}
    >
      <PheonixBox
        sx={{
          display: "flex",
          width: isSmallScreen ? "auto" : isMediumScreen ? "auto" : "856px",
          flexDirection: isSmallScreen
            ? "column"
            : isMediumScreen
            ? "column"
            : "row",
          gap: isSmallScreen ? "16px" : isMediumScreen ? "16px" : "128px",
          overflow: "auto",
          marginTop: "20px",
          height: "auto",
          padding: isSmallScreen ? "16px" : "26px",
          alignItems: "stretch",
        }}
      >
        <PheonixBox
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Autocomplete
            options={productNames.filter((product) =>
              product.name.toLowerCase().includes(searchTerm.toLowerCase())
            )}
            disabled={viewMode}
            getOptionLabel={(option) => option.name || "No Name"}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedProduct}
            onChange={(_, newValue) => {
              setSelectedProduct(newValue || null);
              setError((prev) => ({ ...prev, product: !newValue }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={error.product}
                helperText={error.product ? "Product is required" : ""}
                label="Select Product"
                variant="standard"
              />
            )}
            PaperComponent={({ children }) => (
              <Paper>
                <Box
                  sx={{
                    p: 1,
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <SearchIcon sx={{ mr: 1 }} />
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoFocus
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </Box>
                {children}

                <ListSubheader
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    mt: 1,
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    navigate("/product");
                  }}
                >
                  <ListItemText
                    primary="Add new product"
                    sx={{ color: "gray" }}
                  />
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </ListSubheader>
              </Paper>
            )}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
          <PheonixTextField
            label="Stock Quantity"
            type="text"
            variant="standard"
            required
            error={error.quantity}
            helperText={error.quantity ? "Quantity is required" : ""}
            value={quantity}
            disabled={viewMode}
            onChange={(e) => {
              setQuantity(e.target.value);
              setError((prev) => ({ ...prev, quantity: !e.target.value }));
            }}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />

          <PheonixTextField
            label="Remarks"
            type="text"
            variant="standard"
            value={remarks}
            disabled={viewMode}
            onChange={(e) => setRemarks(e.target.value)}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
        </PheonixBox>
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <PheonixTextField
            label="Entry Date"
            type="date"
            variant="standard"
            disabled={viewMode}
            value={entrydate}
            error={error.entrydate}
            helperText={error.entrydate ? "Entry date is required" : ""}
            onChange={(e) => {
              setEntrydate(e.target.value);
              setError((prev) => ({ ...prev, entrydate: !e.target.value }));
            }}
            InputProps={{
              inputProps: {
                placeholder: entrydate ? " " : "Entry date",
                max: new Date().toISOString().split("T")[0],
              },
            }}
            required
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root input::-webkit-datetime-edit": {
                opacity: entrydate ? 1 : 0,
              },
              "& .MuiInputBase-root": {},
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />

          <Autocomplete
            options={unitOptions}
            disabled={viewMode}
            getOptionLabel={(option) => option.label}
            value={unitOptions.find((option) => option.value === unit) || null}
            onChange={(_, newValue) => {
              setUnit(newValue?.value || null);
              setError((prev) => ({ ...prev, unit: !newValue }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={error.unit}
                helperText={error.unit ? "Unit is required" : ""}
                label="Unit"
                variant="standard"
              />
            )}
            sx={{
              width: isSmallScreen ? "302px" : "348px",
              height: "48px",
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
              },
              "& .MuiInputLabel-root": {
                color: "gray",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                backgroundColor: "#FFFFFF",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "gray",
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
        </Box>
      </PheonixBox>
      <Box
        sx={{
          position: "absolute",
          top: isSmallScreen ? "80%" : isMediumScreen ? "90%" : "85%",
          paddingX: "16px",
          paddingY: "16px",
          bottom: 0,
          width: isSmallScreen ? "80%" : isMediumScreen ? "80%" : "90%",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <PheonixButton
            label={TEXT_MESSAGES.BACK_BUTTON}
            width={"90px"}
            backgroundColor={"white"}
            borderColor={"black"}
            border="1px solid"
            color={"secondary"}
            variant="outlined"
            onClick={onClose}
            sx={{
              color: "black",
              transform: "none",
              textTransform: "none",
              "&:hover": { borderColor: "black", backgroundColor: "white" },
            }}
          />
          {!viewMode && (
            <PheonixButton
              label={TEXT_MESSAGES.ADD}
              variant="outlined"
              width={"90px"}
              onClick={handleSave}
              sx={{
                backgroundColor: "black",
                color: "white",
                transform: "none",
                textTransform: "none",
                "&:hover": { backgroundColor: "black" },
              }}
            />
          )}
        </Box>
      </Box>
    </PheonixBox>
  );
};

export default AddStock;
