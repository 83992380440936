import { DELETE_PRODUCT } from "./graphql/query";

export const TEXT_MESSAGES = {
    LOG_BUTTON:"Sign in to Dasboard",
    EMAIL_REG: /^[a-zA-Z0-9._%+-]+@(?!.*\.\w{0,2}$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    LOG_TXT:"Login",
    FORGOT_TXT:"Forgot Password",
    ENTER:"Enter",
    Progresstext:"In the works, coming your way!",
    PARTY_TXT:"Parties",
    PRODUCT:"Products",
    STOCKS:"Stocks",
    ADD_PARTY:"+ Add Party",
    ADD_STOCK:"+ Add Stock",
    ADD_PRODUCT:"+ Add Product",
    STOCK_BREAD:"Add Stock",
    PARTY_BREAD:"Add Party",
    PDT_BREAD:"Add Product",
    IMPORT_PRODUCT:"Import Bulk Products",
    VIEW_PRODUCT:"View",
    EDIT_PRODUCT:"Edit",
    NO_PRODUCT:"No products are available",
    CURRENT_PRODUCT:"Your Current Products",
    EDIT_BUTTON:"Edit",
    AVAILABLE_SERVICE:"Available Services",
    VIEW:"View",
    BACK_BUTTON:"Back",
    SAVE_BUTTON:"Save",
    NEXT_BUTTON:"Next",
    GST_TYPE:"GST Type",
    STOCK_ALERT_COUNT:"Stock Alert Count",
    VIEW_PRODUCTS:" View Product",
    PRODUCT_DETAILS:"Product Details",
    STOCK_DETAILS:" Stock Details",
    PRICE_DETAILS:"Price Details",
    DELETE_PRODUCT:"Product Deleted Successfully",
    ADDING_PRODUCT:"Product Added Successfully",
    EDITED_PRODUCT:"Product Edited Successfully",
    DELETE_INFO:"Are you sure want to Delete?",
    INFORMATION:"Product",
    NO:"No",
    YES:"YES",
    EDIT_INFO:"Are you sure want to Edit?",
    IMPORT_BULK_PRODUCT:"Import Bulk Product",
    SUPPORT:"Supports only CSV File formats",
    SAMPLE_FILE:"Sample file format",
    CANCEL:"Cancel",
    ERROR_PRODUCT:"Product name already exist",
    ENTER_UNIQUE_NAME:"Enter a unique product name",
    FILE_UPLOAD_SUCCESSFULLY:" File Imported Successfully",
    CURRENT_STOCK:"Your current stock",
    NO_STOCK:"No stock Available",
    IMPORT_BULK_STOCK:"Import Bulk Stock",
    ADD:"Add",


};

  export const ERROR_MESSAGES = {
    ENTER_BOTH: "Please enter both email and password",
    VALID_EMAIL: "Please enter a valid email address",
    VALID_PHONE: "Phone number must be exactly 10 digits",
    VALID_PASSWORD: "Please enter password",
    PWD_LENGTH: "Password should be minimum of 8 characters",
};
  export const baseurl = {
  DEV_URL: "https://dev-api-taxary.pheonixsolutions.com/graphql",
  UAT_URL: "https://uat-api-taxary.pheonixsolutions.com/graphql",
  DEMO_URL:"https://demo.api-taxary.net/graphql",
  LOCAL_URL: "http://localhost:4000/graphql",
  }