import React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import PheonixButton from "../Components/PheonixButton";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import Product from "./Product";
import ProductDetails from "./Productdetails";
import baseUrl from "../api";
import axios from "axios";
import StockDetails from "./Stockdetails";
import PriceDetails from "./Pricedetails";
import { TEXT_MESSAGES } from "../const";
import { styled } from "@mui/material/styles";
import { ADD_PRODUCT_MUTATION } from "../graphql/query";
import { print } from "graphql";
import { gql } from "graphql-tag";

interface AddProductProps {
  onClose: () => void;
  onSave: (data: any) => void;
  activeStep : number, 
  setActiveStep : React.Dispatch<React.SetStateAction<number>>,
}
interface ProductData {
  productDetails: Record<string, any>;
  stockDetails: Record<string, any>;
  priceDetails: Record<string, any>;
}

const AddProduct: React.FC<AddProductProps> = ({ onClose, onSave, setActiveStep, activeStep }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setError] = useState<string | null>(null);
  const [productData, setProductData] = useState<ProductData>({
    productDetails: {},
    stockDetails: {},
    priceDetails: {},
  });

  const steps = ["Product Details", "Stock Details", "Price Details"];
  const queryString = print(ADD_PRODUCT_MUTATION);
  const validateProductDetails = (
    data: Record<string, any>,
    checkAll = false
  ) => {
    const requiredFields = [
      "product_name",
      "product_description",
      "quantity",
      "unit",
      "entrydate",
      "expirydate",
    ];

    if (data.isTaxable) {
      requiredFields.push("gst_type","gst_percentage");
    }

    let errors: string[] = [];
    requiredFields.forEach((field) => {
      if (!data[field]) {

        errors.push(`${field.replace(/_/g, " ")} is required`);
      }
    });
   
    return errors;
  };

  const validateStockDetails = (
    data: Record<string, any>,
    checkAll = false
  ) => {
    const requiredFields = ["open_stock"];
    let errors: string[] = [];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors.push(`${field.replace(/_/g, " ")} is required`);
      }
    });
    return errors;
  };

  const validatePriceDetails = (
    data: Record<string, any>,
    checkAll = false
  ) => {
    const requiredFields = [
      "purchase_price",
      "purchase_rate",
      "purchase_unit",
      "purchase_gst",
      "purchase_price_gst",
      "sales_price",
      "sales_rate",
      "sales_unit",
      "sales_gst",
      "sales_price_gst",
    ];
    let errors: string[] = [];
    requiredFields.forEach((field) => {
      if (!data[field]) {
        errors.push(`${field.replace(/_/g, " ")} is required`);
      }
    });
    return errors;
  };

  const handleNext = async () => {
    let currentErrors: string[] = [];
    if (activeStep === 0) {
      currentErrors = validateProductDetails(productData.productDetails, false);
    } else if (activeStep === 1) {
      currentErrors = validateStockDetails(productData.stockDetails, false);
    } else if (activeStep === 2) {
      currentErrors = validatePriceDetails(productData.priceDetails, false);
    }
     
    if (currentErrors.length > 0) {
      setError(currentErrors.join(", "));
      return;
    }
    setError(null);
    if (activeStep === steps.length - 1) {
      try {
        const variables = {
          ...productData.productDetails,
          ...productData.stockDetails,
          ...productData.priceDetails,
        };

        const response = await axios.post(baseUrl, {
          query: ADD_PRODUCT_MUTATION.loc?.source.body,
          variables,
        });

        const { addProduct } = response.data.data;

        if (addProduct?.statusCode === 201) {
          onSave(productData);
          onClose();
        } else {
          setError(addProduct?.message || "Unknown error");
        }
      } catch (error: any) {
        console.error("Network error:", error.message || error);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleDataChange = (key: keyof ProductData, data: any) => {
    setProductData((prev: ProductData) => ({
      ...prev,
      [key]: data,
    }));
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        margin: "auto",
        paddingX: "16px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: "1px solid",
        borderColor: "rgba(224,224,224,1)",
        height: isSmallScreen ? "90vh" : isMediumScreen ? "80vh" :"70vh",
        overflowY: "auto",
        boxSizing: "border-box",
        
      }}
    >
      <Typography variant="h5" fontWeight="bold" sx={{ fontFamily:"Lexend",}}>
        {TEXT_MESSAGES.PDT_BREAD}
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",  
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {["Product Details", "Stock Details", "Price Details"].map(
            (label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    sx: {
                      color: "#8C8C8C",
                      "&.Mui-active": {
                        color: "black",
                      },
                      "&.Mui-completed": {
                        color: "black",
                      },
                    },
                  }}
                  sx={{
                    fontFamily: "Lexend", 
                    "& .MuiStepLabel-label": {
                      fontFamily: "Lexend", 
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            )
          )}
        </Stepper>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {activeStep === 0 && (
            <ProductDetails
              data={productData.productDetails || {}}
              onSave={(data) => {
                handleDataChange("productDetails", data);
              }}
              error={error}
              readOnly={false}
              checkAll={true}
              
            />
          )}

          {activeStep === 1 && (
            <StockDetails
              data={productData.stockDetails || {}}
              onSave={(data) => {
                handleDataChange("stockDetails", data);
              }}
              error={error}
              readOnly={false}
            />
          )}

          {activeStep === 2 && (
            <PriceDetails
              data={productData.priceDetails || {}}
              onSave={(data) => {
                handleDataChange("priceDetails", data);
              }}
              error={error}
              readOnly={false}
            />
          )}
        </Box>
        <Box
          sx={{
            top: isSmallScreen ? "90%" : isMediumScreen ? "auto" : "85%",
            paddingX: "16px",
            paddingY: "10px",
            width: isSmallScreen ? "85%" : isMediumScreen ? "80%" : "90%",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {activeStep > 0 && (
              <PheonixButton
                label={TEXT_MESSAGES.BACK_BUTTON}
                width={isSmallScreen ? "79px" : "90px"}
                backgroundColor={"white"}
                borderColor={"black"}
                border="1px solid"
                color={"secondary"}
                variant="outlined"
                onClick={handleBack}
                sx={{
                  color: "black",
                  minWidth: "79px",
                  minHeight: "33px",
                  transform: "none",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "white",
                  },
                }}
              ></PheonixButton>
            )}
            <PheonixButton
              label={
                activeStep === 2
                  ? TEXT_MESSAGES.SAVE_BUTTON
                  : TEXT_MESSAGES.NEXT_BUTTON
              }
              variant="outlined"
              width={isSmallScreen ? "79px" : "90px"}
              onClick={handleNext}
              sx={{
                backgroundColor: "black",
                minWidth: "79px",
                minHeight: "33px",
                color: "white",
                transform: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            ></PheonixButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddProduct;
