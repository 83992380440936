import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PheonixBreadcrumbsProps from "../interfaces/PheonixBreadcrumProps";

const PheonixBreadcrumbs: React.FC<PheonixBreadcrumbsProps> = ({
  breadcrumbText,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        underline="hover"
        color="inherit"
        href="/home"
        onClick={() => navigate("/home")}
        sx={{ display: "flex", alignItems: "center",fontFamily: "Lexend",  }}
      >
        Dashboard
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return (
          <React.Fragment key={to}>
            <Link
              underline="none"
              color="inherit"
              onClick={() => navigate(to)}
              sx={{ display: "flex", alignItems: "center",fontFamily: "Lexend",  }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Link>
            {!last && (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "4px",
                  fontFamily: "Lexend", 
                }}
              >
                /
              </Typography>
            )}
          </React.Fragment>
        );
      })}
      {breadcrumbText && (
        <Typography
          color="text.primary"
          sx={{ display: "flex", alignItems: "center", marginLeft: "4px", fontFamily:"Lexend"}}
        >
          {breadcrumbText}
        </Typography>
      )}
      {!breadcrumbText && (
        <Typography
          color="text.primary"
          sx={{ display: "flex", alignItems: "center", marginLeft: "4px", fontFamily:"Lexend"}}
        >
          {pathnames[pathnames.length - 1].charAt(0).toUpperCase() +
            pathnames[pathnames.length - 1].slice(1)}{" "}
          Listing
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default PheonixBreadcrumbs;
