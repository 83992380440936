import React from "react";
import { useState, useEffect } from "react";
import { Box, Typography, Switch } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixTextField from "../Components/PheonixTextField";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { TEXT_MESSAGES } from "../const";
import baseUrl from "../api";
import axios from "axios";
import { PDF_UPLOAD } from "../graphql/query";

interface StockDetailsProps {
  data: Record<string, any>;
  onSave: (data: Record<string, any>) => void;
  error?: string | null;
  readOnly?: boolean;
}

const StockDetails: React.FC<StockDetailsProps> = ({ data, onSave, error, readOnly}) => {
  const [isalert, setIsalert] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [formData, setFormData] = useState(data);
  const commonStyles = {
    textField: {
      width: "100%",
      maxWidth: "348px",
      height: "48px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent",
      },
      "& .MuiInputLabel-root": {
        color: "gray",
        fontFamily: "Lexend",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "black",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "gray",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
      },
      "& .MuiFormLabel-asterisk": {
        color: "red",
      },
    },
  };
  const gstType = [
    { value: "Taxable", label: "Taxable" },
    { value: "Non-Taxable", label: "Non-Taxable" },
    { value: "Out of scope", label: "Out of scope" },
    { value: "Non-GST supply", label: "Non-GST supply" },
  ];
  const gstOptions = [
    { value: "0%", label: "0%" },
    { value: "5%", label: "5%" },
    { value: "12%", label: "12%" },
    { value: "18%", label: "18%" },
    { value: "28%", label: "28%" },
  ];

  const unitOptions = [
    { value: "Bags", label: "Bags" },
    { value: "kilogram", label: "kilogram" },
    { value: "gram", label: "gram" },
    { value: "centimeter", label: "centimeter" },
  ];

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const formatDate = (date: string): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleupload = async (file: File) => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append(
          "operations",
          JSON.stringify({
            query: PDF_UPLOAD.loc?.source.body,
            variables: { file: null },
          })
        );
        formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
        formData.append("0", file);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(baseUrl, formData, config);

        const { success, message, filePath } = response.data.data.uploadPDF;

        if (success) {
        } else {
          console.error(
            "Upload failed:",
            message || "Unknown error from server"
          );
        }
      } catch (error: any) {
        console.error("Network error:", error.message || error);
      }
    } else {
      console.warn("No file selected for upload.");
    }
  };

  const handleInputChange = (field: string, value: any) => {
    let parsedValue = value;

    const integerFields = ["open_stock", "stock_alert"];

    if (integerFields.includes(field)) {
      parsedValue = value === "" ? "" : parseInt(value, 10) || 0;
    }

    const updatedData = { ...formData, [field]: parsedValue };
    setFormData(updatedData);

    onSave(updatedData);
  };

  const handleSwitchChange = () => {
    setIsalert(!isalert);
  };

  const handleSave = () => {
    onSave(formData);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen
          ? "column"
          : isMediumScreen
          ? "column"
          : "row",
        gap: isSmallScreen ? "16px" : "24px",
        padding: isSmallScreen ? "16px" : "32px",
        height: "auto",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: { xs: "12px", sm: "16px" },
        }}
      >
        <PheonixTextField
          label="Entry date"
          type="date"
          disabled={readOnly}
          variant="standard"
          value={formData.entrydate ? formatDate(formData.entrydate) : ""}
          onChange={(e) => handleInputChange("entrydate", e.target.value)}
          InputProps={{
            inputProps: {
              placeholder: formData.entrydate ? " " : " Entry date",
              max: new Date().toISOString().split("T")[0],
            },
          }}
          required
          sx={{
            width: isSmallScreen ? "100%" : "348px",
            height: "48px",
            "& .MuiInputBase-root input::-webkit-datetime-edit": {
              opacity: formData.entrydate ? 1 : 0,
            },
            "& .MuiInputBase-root": {},
            "& .MuiInputLabel-root": {
              color: "gray",
              fontFamily: "Lexend",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black",
              backgroundColor: "#FFFFFF",
              fontFamily: "Lexend",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "gray",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "gray",
            },
            "& .MuiFormLabel-asterisk": {
              color: "red",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: { xs: "100%", sm: "348px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: { xs: "12px", sm: "14px" },
                whiteSpace: "nowrap",
                fontFamily: "Lexend",
              }}
            >
              {TEXT_MESSAGES.STOCK_ALERT_COUNT}
            </Typography>
            <Switch
              checked={readOnly ? formData.stock_alert > 0 : isalert}
              disabled={readOnly}
              onChange={handleSwitchChange}
              size="small"
              sx={{
                width: "40px",
                height: "20px",
                padding: "0",
                "& .MuiSwitch-thumb": {
                  width: "16px",
                  height: "16px",
                  backgroundColor: "black",
                  boxShadow: "none",
                },
                "& .MuiSwitch-switchBase": {
                  padding: "2px",
                  "&.Mui-checked": {
                    transform: "translateX(20px)",
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "black",
                    },
                  },
                },
                "& .MuiSwitch-track": {
                  borderRadius: "10px",
                  backgroundColor: "gray",

                  width: "26px",
                  height: "10px",
                  margin: "5px",
                  transition: "background-color 0.2s",
                  "&.Mui-checked": {
                    backgroundColor: "black",
                  },
                },
              }}
            />
          </Box>
          <PheonixTextField
            type="text"
            variant="standard"
            disabled={readOnly}
            value={formData.stock_alert}
            onChange={(e) => handleInputChange("stock_alert", e.target.value)}
            sx={{
              width: "100%",
              top: 0,
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
              },
              "& .MuiInputLabel-root": {
                color: "#00000061",
                fontFamily: "Lexend",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "gray",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              visibility: readOnly || isalert ? "visible" : "hidden",
              opacity: readOnly || isalert ? 1 : 0,
              transition: "opacity 0.2s linear",
            }}
          />
        </Box>
        <PheonixTextField
          type="text"
          label="Stock Notes"
          variant="standard"
          disabled={readOnly}
          value={formData.stock_notes}
          onChange={(e) => handleInputChange("stock_notes", e.target.value)}
          sx={commonStyles.textField}
        />
      </Box>

      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <PheonixTextField
          type="text"
          id="outlined-error"
          label="Opening Stock"
          disabled={readOnly}
          variant="standard"
          value={formData.open_stock}
          error={!!error && (formData.open_stock === "" || formData.open_stock == null)}
          helperText={
            !!error && (formData.open_stock === "" || formData.open_stock == null)
              ? "Opening stock is required"
              : ""
          }
        
          onChange={(e) => handleInputChange("open_stock", e.target.value)}
          required
          sx={commonStyles.textField}
        />

        <PheonixTextField
          label="Stock Reference"
          type="text"
          disabled={readOnly}
          variant="standard"
          value={file ? file.name : ""}
          onChange={(e) => handleInputChange("stock_reference", e.target.value)}
          InputProps={{
            endAdornment: (
              <AttachFileIcon
                sx={{
                  width: { xs: "100%", sm: "348px" },
                  cursor: "pointer",
                  color: "gray",
                  "&:hover": {
                    color: "black",
                  },
                }}
                onClick={() => {
                  document.getElementById("file-upload")?.click();
                }}
              />
            ),
          }}
          sx={commonStyles.textField}
        />
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          accept=".pdf"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setFile(file);
              handleupload(file);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default StockDetails;
