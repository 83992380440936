import { gql } from "@apollo/client";
export const GET_PRODUCT_QUERY = gql`
  query GetProductsperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getProductsperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      product {
        id
        product_code
        product_name
        product_description
        quantity
        unit
        gst_type
        gst_percentage
        hsn_code
        date
        entrydate
        expirydate
        open_stock
        stock_alert
        stock_reference
        stock_notes
        purchase_price
        purchase_rate
        purchase_unit
        purchase_gst
        purchase_price_gst
        sales_price
        sales_rate
        sales_unit
        sales_gst
        sales_price_gst
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    getAllProducts {
      products {
        id
        product_name
      }
    }
  }
`;

export const GET_PARTIES_QUERY = gql`
  query GetPartiesperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getPartiesperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      party {
        id
        party_type
        customer_id
        supplier_id
        name
        email
        phone
        state
        gstin
        shipping_addr
        billing_addr
        credit_period
        credit_limit
        open_bal
        bal_type
      }
    }
  }
`;
export const GET_STOCK_QUERY = gql`
  query GetStocksperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getStocksperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      stock {
        id
        product_name
        quantity
        unit
        entrydate
        remarks
      }
    }
  }
`;

export const ADD_PRODUCT_MUTATION = gql`
  mutation AddProduct(
    $product_name: String
    $product_description: String
    $quantity: Int
    $unit: String
    $gst_type: String
    $gst_percentage: String
    $entrydate: Date
    $expirydate: Date
    $hsn_code: Int
    $stock_alert: Int
    $open_stock: Int
    $stock_reference: String
    $stock_notes: String
    $purchase_price: Float
    $purchase_rate: Float
    $purchase_unit: String
    $purchase_gst: String
    $purchase_price_gst: Float
    $sales_price: Float
    $sales_rate: Float
    $sales_unit: String
    $sales_gst: String
    $sales_price_gst: Float
  ) {
    addProduct(
      product_name: $product_name
      product_description: $product_description
      quantity: $quantity
      unit: $unit
      gst_type: $gst_type
      gst_percentage: $gst_percentage
      entrydate: $entrydate
      expirydate: $expirydate
      hsn_code: $hsn_code
      stock_alert: $stock_alert
      open_stock: $open_stock
      stock_reference: $stock_reference
      stock_notes: $stock_notes
      purchase_price: $purchase_price
      purchase_rate: $purchase_rate
      purchase_unit: $purchase_unit
      purchase_gst: $purchase_gst
      purchase_price_gst: $purchase_price_gst
      sales_price: $sales_price
      sales_rate: $sales_rate
      sales_unit: $sales_unit
      sales_gst: $sales_gst
      sales_price_gst: $sales_price_gst
    ) {
      statusCode
      message
      product {
        id
        product_name
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation EditProduct(
    $id: ID!
    $product_name: String
    $product_description: String
    $quantity: Int
    $unit: String
    $gst_type: String
    $gst_percentage: String
    $entrydate: Date
    $expirydate: Date
    $hsn_code: Int
    $stock_alert: Int
    $open_stock: Int
    $stock_reference: String
    $stock_notes: String
    $purchase_price: Float
    $purchase_rate: Float
    $purchase_unit: String
    $purchase_gst: String
    $purchase_price_gst: Float
    $sales_price: Float
    $sales_rate: Float
    $sales_unit: String
    $sales_gst: String
    $sales_price_gst: Float
  ) {
    editProduct(
      id: $id
      product_name: $product_name
      product_description: $product_description
      quantity: $quantity
      unit: $unit
      gst_type: $gst_type
      gst_percentage: $gst_percentage
      entrydate: $entrydate
      expirydate: $expirydate
      hsn_code: $hsn_code
      stock_alert: $stock_alert
      open_stock: $open_stock
      stock_reference: $stock_reference
      stock_notes: $stock_notes
      purchase_price: $purchase_price
      purchase_rate: $purchase_rate
      purchase_unit: $purchase_unit
      purchase_gst: $purchase_gst
      purchase_price_gst: $purchase_price_gst
      sales_price: $sales_price
      sales_rate: $sales_rate
      sales_unit: $sales_unit
      sales_gst: $sales_gst
      sales_price_gst: $sales_price_gst
    ) {
      statusCode
      message
      product {
        id
        product_name
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      statusCode
      message
    }
  }
`;

export const UPLOAD_CSV=gql `
mutation uploadCSV($file: Upload!) {
  uploadCSV(file: $file) {
    success
    message
  }
}
`;
export const PDF_UPLOAD=gql`
mutation UploadStockReferencePDF($productId: ID!, $file: Upload!) {
  uploadStockReferencePDF(productId: $productId, file: $file) {
    success
    message
    fileUrl
  }
}
`

export const ADD_STOCK_MUTATION = gql`
mutation AddStock (
    $product_name: String
    $quantity: Int
    $unit: String
    $entrydate: Date
    $remarks:String
  ){
    addStock (
    product_name:$product_name
    quantity:$quantity
    unit:$unit
    entrydate:$entrydate
    remarks:$remarks
    ) {
      statusCode
      message
      stock {
        id
        product_name
        quantity
        unit
        entrydate
        remarks

      }
    }
    }
`
export const EDIT_STOCK_MUTATION = gql`
mutation EditStock (
    $id: ID!
    $product_name: String
    $quantity: Int
    $unit: String
    $entrydate: Date
    $remarks:String
  ){
    editStock (
    id: $id
    product_name:$product_name
    quantity:$quantity
    unit:$unit
    entrydate:$entrydate
    remarks:$remarks
    ) {
      statusCode
      message
      stock {
        id
        product_name
        quantity
        unit
        entrydate
        remarks

      }
    }
    }
    `
export const DELETE_STOCK = gql`
  mutation DeleteStock($id: ID!) {
    deleteStock(id: $id) {
      statusCode
      message
    }
  }
`;

export const UPLOAD_STOCK_CSV=gql `
mutation uploadStockCSV($file: Upload!) {
  uploadStockCSV(file: $file) {
    success
    message
  }
}
`;