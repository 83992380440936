import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import PheonixButtonProps from '../interfaces/PheonixButtonProps';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";


const PheonixButton: React.FC<PheonixButtonProps> = ({ 
  label,
  disabled,
  onClick, 
  startIcon, 
  endIcon, 
  width,
  backgroundColor,
  borderColor,
  color,
  border,
  ...props 
  }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const buttonWidth = width || (isMobile ? '250px' : '350px');
  const buttonBackgroundColor = backgroundColor || (disabled ? 'rgba(0, 0, 0, 0.33)' : '#152726');
  const Textcolor =  color || 'white';
  const borderSolid= border || '1px'

  return (
    <MuiButton disabled={disabled} onClick={onClick} variant="outlined"  
    style={{backgroundColor: buttonBackgroundColor,color: Textcolor, padding: '5px', 
    width: buttonWidth, height: '42px',borderRadius:'4px',border: borderSolid,boxSizing:'border-box',
    fontFamily:"Lexend",
  }} 
    
    startIcon={startIcon} 
    endIcon={endIcon} 
    {...props}  >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;