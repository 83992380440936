import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import PheonixTextField from "../Components/PheonixTextField";
import ModalFormData from "../interfaces/ModalFormDataProps";
import PriceDetails from "./Pricedetails";
import ProductDetails from "./Productdetails";
import StockDetails from "./Stockdetails";
import { TEXT_MESSAGES } from "../const";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PheonixButton from "../Components/PheonixButton";

interface ProductViewProps {
  product: ModalFormData;
  onClose: () => void;
  onSave: (updatedProduct: ModalFormData) => void;
}

const ProductView: React.FC<ProductViewProps> = ({
  product,
  onClose,
  onSave,
}) => {
  const [editedProduct, setEditedProduct] = useState<ModalFormData>(product);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleChange = (field: keyof ModalFormData, value: any) => {
    setEditedProduct((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    onSave(editedProduct);
  };
  return (
    <Box
      sx={{
        maxWidth: isSmallScreen ? "100%" : isTabletScreen ? "90%" : "1200px",
        height: isSmallScreen ? "auto" : "auto",
        margin: "auto",
        padding: isSmallScreen ? "12px" : isTabletScreen ? "20px" : "24px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: isSmallScreen ? 16 : 20,
          fontFamily: "Lexend", 
        }}
      >
        {TEXT_MESSAGES.VIEW_PRODUCTS}
      </Typography>

      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Typography
          sx={{
            width: "90%",
            height: "32px",
            paddingY: "4px",
            paddingLeft: "16px",
            backgroundColor: "#00000026",
            fontFamily: "Lexend", 
          }}
        >
          {TEXT_MESSAGES.PRODUCT_DETAILS}
        </Typography>
        <ProductDetails
          data={product}
          readOnly={true}
          onSave={(updatedData) => {
            setEditedProduct((prev) => ({
              ...prev,
              ...updatedData,
            }));
          }}
        />
      </Box>

      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Typography
          sx={{
            width: "90%",
            height: "32px",
            paddingY: "4px",
            paddingLeft: "16px",
            backgroundColor: "#00000026",
            fontFamily: "Lexend", 
          }}
        >
          {TEXT_MESSAGES.STOCK_DETAILS}
        </Typography>
        <StockDetails
          data={product}
          readOnly={true}
          onSave={(updatedData) => {
            setEditedProduct((prev) => ({
              ...prev,
              ...updatedData,
            }));
          }}
        />
      </Box>

      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Typography
          sx={{
            width: "90%",
            height: "32px",
            paddingY: "4px",
            paddingLeft: "16px",
            backgroundColor: "#00000026",
            fontFamily: "Lexend", 
          }}
        >
          {TEXT_MESSAGES.PRICE_DETAILS}
        </Typography>
        <PriceDetails
          data={product}
          readOnly={true}
          onSave={(updatedData) => {
            setEditedProduct((prev) => ({
              ...prev,
              ...updatedData,
            }));
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          gap: "12px",
          flexWrap: "wrap",
        }}
      >
        <PheonixButton
          label={TEXT_MESSAGES.BACK_BUTTON}
                        width={isSmallScreen ? "79px" : "90px"}
                        backgroundColor={"white"}
                        borderColor={"black"}
                        border="1px solid"
                        color={"secondary"}
                        variant="outlined"
                       
                        sx={{
                          color: "black",
                          minWidth: "79px",
                          minHeight: "33px",
                          transform: "none",
                          textTransform: "none",
                          "&:hover": {
                            borderColor: "black",
                            backgroundColor: "white",
                          },
                        }}
          onClick={onClose}
        />
      </Box>
    </Box>
  );
};

export default ProductView;
